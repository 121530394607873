
import { ScholarDto, ProgramSessionDetailsDto, ParticipantTableRow } from '@/Model/model';
import { DeleteParticipantPayload } from '@/Model/shared/types';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import ActionButton from '@/commoncomponents/ActionButton.vue';
import ActionButtonGroup from '@/commoncomponents/ActionButtonGroup.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import SmartTableContainer from '@/commoncomponents/smartTableComponents/SmartTableContainer.vue';
import { ColumnConfig, TableHeaderConfig } from '@/commoncomponents/smartTableComponents/types';
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
import accountParticipantsStore from '@/store/modules/accountParticipants';
import programList from '@/store/modules/programList';
import table from '@/store/modules/table';
import APP_UTILITIES from '@/utilities/commonFunctions';
import LocalStorage from "@/utilities/localStorageUtil";
import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import ParticipantDetails from './ParticipantDetails.vue';
import ParticipantTableHelper from '@/utilities/ParticipantTableHelper';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';
import DeleteModalComponent from '@/components/accountDetailsComponent/accountDataTabs/modals/deleteModal/DeleteModal.vue';
import { ToastType } from '@/Model/toastType';
import UIkit from "uikit";
import { deleteParticipants, getParticipantsCsv } from '@/services/enrollment/enrollmentService';

@Component({
  components: {
    SmartTableContainer,
    BouncingPreloaderComponent,
    OffCanvas,
    ParticipantDetails,
    ActionButton,
    ActionButtonGroup,
    DeleteModalComponent,
  }
})
export default class Participants extends Vue {
  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  public accountId: number = Number(APP_UTILITIES.getCookie('accountId'));
  public tableHeaderConfig: TableHeaderConfig = {
    FIXED: [
      {
        name: 'First name',
        value: 'firstName',
        action: true,
        isOpen: false,
        width: '217px',
        isSort: true,
        isSearch: false,
        isSearchList: false,
        display: true,
      },
      {
        name: 'Last name',
        value: 'lastName',
        action: true,
        isOpen: false,
        width: '217px',
        isSort: true,
        isSearch: false,
        isSearchList: false,
        display: true,
      },
    ],
    MOVABLE: []
  };
  public tableName: string = 'participants-table';
  public items: ParticipantTableRow[] = [];
  public totalItems = 0;
  public initialPage: number | null = 0;
  public isSearching = false;
  public displayColumn: ColumnConfig[] = [];
  public keys: string[] = ['firstName', 'lastName', 'grade', 'guardians', 'programsAndSessions'];
  public page = 1;
  public search = '';
  public searchCount: number = APP_CONST.DEFAULT_TABLE_SEARCH_ROWS;
  public hoverText = '';
  public hoverStyleObj: Record<string, string> = {};
  public hoverId = 0;
  public globalSearch = '';
  public searchField = '';
  public isResizer = true;
  public isSortingSearching = true;
  public responseStatus = 0;
  sortDir = 0;
  sortField = 'firstName';
  public isSearch = false;
  public pageStatus = false;
  public localSearch = false;
  public isSearchRes = true;
  public globalSearchEmptyState = false;
  public globalSearchApiStatus = 0;
  public searchLoader = false;
  public disableDownloadButton = false;
  public noDataApiResponseCode = APP_CONST.RESPONSE_204;
  public currentPayload = APP_CONST.SCHOLAR_FAMILY_PAYLOAD;
  public displayAll = true;
  public loggedInUserId: string | null = '';
  private objScreenText = new ScreenText();
  private profilesAdminViewEnabled: boolean = false;

  public initialTotalItems: number = APP_CONST.ZERO;
  public localConst = APP_CONST;
  public isClearSearch = false;
  public isCountAvailable = false;
  public countOrName: number | string = '';
  public showFlyoutPanel = false;
  public selectedParticipant: ScholarDto | null = null;

  public gradeSearchList: string[] = [];

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  @Watch('globalSearch', { deep: true})
  watchGlobalSearch(globalSearch: string): void{
    APP_UTILITIES.setCookie('participants_globalSearch', globalSearch, 1);
  }

  get participantsPayload() {
    return accountParticipantsStore.participantsTabPayload;
  }

  get selectedCount(): number {
    return this.selectedItems.length;
  }

  get selectedItems(): ParticipantTableRow[] {
    return table.allDataSelected.filter((item: ParticipantTableRow) => item.selected);
  }

  get shouldShowActions(): boolean {
    return this.selectedCount > 0;
  }

  get participantsResizeArray() {
    return accountParticipantsStore.participantsResizeArray;
  }

  get inviteScheduleDate() {
    return programList.scheduleDateInvitation;
  }

  get accountDetails() {
    return programList.accountInfo;
  }

  get addEditViewModeValue() {
    return programList.addEditViewModeValue;
  }

  get searchParticipant() {
    return accountParticipantsStore.searchParticipant;
  }

  get isLoaderVisible() {
    return (this.initialTotalItems >= this.localConst.HUNDRED || this.initialTotalItems <= this.localConst.ZERO);
  }

  get searchLists(): { header: string; values: string[] }[] {
    return [{ header: 'grade', values: this.gradeSearchList }];
  }

  get deleteActionCopy(): object {
    const isMultiple = this.selectedCount > 1;
    const participantLabel = this.objScreenText.getScreenText(isMultiple ? 'PARTICIPANTS' : 'PARTICIPANT');

    return {
      actionLabel: this.objScreenText.getScreenTextWithParams('DELETE_USER_ACTION', participantLabel),
      countLabel: isMultiple ? this.selectedCount : 0,
      disclaimerMessage1: this.objScreenText.getScreenText('DELETE_USER_DISCLAIMER1'),
      disclaimerMessage2: this.objScreenText.getScreenText('DELETE_USER_DISCLAIMER2'),
      disclaimerMessage3: this.objScreenText.getScreenTextWithParams('DELETE_USER_DISCLAIMER3', participantLabel)
    };
  }

  beforeMount(): void {
    const storedAccountId: string | null = APP_UTILITIES.getCookie('accountId');
    const storedSearchCount = LocalStorage.getFromLocalStorage(`${this.tableName}_searchCount`);

    this.searchCount = storedSearchCount ? Number(storedSearchCount) : APP_CONST.DEFAULT_TABLE_SEARCH_ROWS;

    accountParticipantsStore.mutateParticipantsPayload(JSON.parse(JSON.stringify(APP_CONST.SCHOLAR_FAMILY_PAYLOAD)));
    accountParticipantsStore.mutateParticipantsPayload({
      ...accountParticipantsStore.participantsPayload,
      accountId: parseInt(storedAccountId || '0'),
      search: '',
      count: this.searchCount
    });
  }

  async created(): Promise<void> {
    this.profilesAdminViewEnabled = await APP_UTILITIES.getFeatureFlag(APP_CONST.FEATURE_KEYS.profilesAdminView);

    const globalSearch = APP_UTILITIES.getCookie('participants_globalSearch');
    this.globalSearch = String(globalSearch === 'null' || !globalSearch
      ? ''
      : globalSearch);
    accountParticipantsStore.mutateCurrentObj({ ...this.searchParticipant, firstName: '', lastName: '', grade: '', guardians: '', programsAndSessions: '' });
  }

  async mounted() {
    await this.handleParticipantsTable();
    this.loggedInUserId = APP_UTILITIES.getCookie('user_id');
  }

  async handleParticipantsTable(): Promise<void> {
    const authKey = APP_UTILITIES.authTokenKeyToManage()
      ? APP_UTILITIES.authTokenKeyToManage()
      : '';
    this.search = this.participantsPayload && this.participantsPayload.search
      ? this.participantsPayload.search
      : this.search;
    this.searchField = this.participantsPayload && this.participantsPayload.searchField
      ? this.participantsPayload.searchField
      : this.searchField;
    this.sortDir = this.participantsPayload && this.participantsPayload.sortDir
      ? this.participantsPayload.sortDir
      : this.sortDir;
    this.sortField = this.participantsPayload && this.participantsPayload.sortField
      ? this.participantsPayload.sortField
      : this.sortField;
    this.manageList();
    let accountId: string | null = null;
    const userRoles = APP_UTILITIES.getCookie('highest_role');
    if (userRoles && Number.parseInt(userRoles, 10) >= APP_CONST.ROLES.ACCOUNT_ADMIN) {
      const role = APP_UTILITIES.getCookie('user_role');
      if (role) {
        const userData = JSON.parse(role);
        accountId = userData[0].accountId;
      }
    }
    else {
      accountId = APP_UTILITIES.getCookie('accountId');
    }

    if (accountId) {
      await accountParticipantsStore.setParticipantColumnConfig(accountId);
      this.displayColumn = accountParticipantsStore.participantColumnConfig;
      this.tableHeaderConfig.MOVABLE = accountParticipantsStore.participantColumnConfig;
    }

    if (APP_UTILITIES.getFilteredColumns(`${authKey}-ParticipantSettingsData`)) {
      let participantSettingsData: any = APP_UTILITIES.getFilteredColumns(`${authKey}-ParticipantSettingsData`);
      participantSettingsData = JSON.parse(participantSettingsData);
      if (participantSettingsData.hasOwnProperty([accountId])) {
        Object.keys(participantSettingsData).forEach(key => {
          if (String(key) === String(accountId)) {
            const participantsColumnsInLocalStorage = participantSettingsData[key];

            if (participantsColumnsInLocalStorage && participantsColumnsInLocalStorage.length) {
              const columnsConfig = this.getColumnConfigFromLocalStorage(participantsColumnsInLocalStorage);
              this.displayColumn = columnsConfig;
            }
          }
        });
      }
    }
    this.isClearSearch = false;
  }

  async manageList() {
    const AUTHORIZED_PICKUP_PREFIX = 'ap';
    const EMERGENCY_CONTACT_PREFIX = 'ec';
    const CUSTOM_FIELD_PREFIX = 'custom';
    const PARTICIPANT_EMPTY_ADDRESS = ', , , , ';

    let accountId;
    const userRoles = APP_UTILITIES.getCookie('highest_role');
    if (userRoles && Number.parseInt(userRoles, 10) >= APP_CONST.ROLES.ACCOUNT_ADMIN) {

      const role = APP_UTILITIES.getCookie('user_role');
      if (role) {
        const userData = JSON.parse(role);
        accountId = userData[0].accountId;
      }
    }
    else {
      accountId = APP_UTILITIES.getCookie('accountId');
    }
    this.isSearchRes = false;
    const payload = APP_CONST.SCHOLAR_FAMILY_PAYLOAD;
    if (!this.globalSearch.trim().length || this.globalSearch.trim().length >= 3) {
      payload.search = this.search;
      payload.accountId = Number(accountId);
      payload.page = this.page;
      payload.count = this.searchCount;
      payload.sortField = this.sortField
        ? this.sortField
        : 'firstName';
      payload.sortDir = this.sortDir
        ? this.sortDir
        : 1;
      payload.globalSearch = this.globalSearch;
      payload.searchField = this.searchField;
      payload.isExactMatch = this.participantsPayload && this.participantsPayload.isExactMatch
        ? this.participantsPayload.isExactMatch
        : false;
      this.currentPayload = payload;
      if (this.searchField.length) {
        this.localSearch = true;
      }
      else if (!this.searchField.length && this.responseStatus != 204 && this.globalSearch) {
        this.localSearch = false;
      }
      this.responseStatus = 0;
      const participantsData = await accountParticipantsStore.fetchList(payload);
      this.pageStatus = true;
      if (this.globalSearch.trim().length >= 3) {
        this.globalSearchApiStatus = participantsData.status;
      }
      if (participantsData.status === 200) {
        this.items = [];
        this.gradeSearchList = [];
        this.responseStatus = participantsData.status;
        participantsData.data.scholars.forEach((scholar) => {
          const guardianDetails = scholar.guardianDetails || []

          this.items.push({
            ...scholar,
            guardians: (guardianDetails)
              .map(({ firstName, lastName }) => [firstName, lastName].filter(Boolean).join(' '))
              .filter(Boolean)
              .join(', '),
            programsAndSessions: this.getProgramAndSession(scholar.programSessionDetails),
            selected: false,
            scholarAddress: scholar.scholarAddress && scholar.scholarAddress !== PARTICIPANT_EMPTY_ADDRESS ? scholar.scholarAddress : '',
            phone: scholar.phone ? `${APP_UTILITIES.formatNumber(scholar.phone)} ${scholar.phoneType || ''}` : '',
            ...ParticipantTableHelper.flatGuardianDetails(guardianDetails),
            ...ParticipantTableHelper.flatContactDetails(scholar.authorisedPickups, AUTHORIZED_PICKUP_PREFIX),
            ...ParticipantTableHelper.flatContactDetails(scholar.emergencyContacts, EMERGENCY_CONTACT_PREFIX),
            ...ParticipantTableHelper.flatCustomFields(scholar.customFields, CUSTOM_FIELD_PREFIX),
            ...ParticipantTableHelper.flatMedicalDetails(scholar.scholarMedical),
            ...ParticipantTableHelper.flatLearningDetails(scholar.scholarLearning),
            rowKey: scholar.guid,
          });

          const gradeFilter = scholar.grade || '--';

          if (!this.gradeSearchList.includes(gradeFilter)) {
            this.gradeSearchList.push(gradeFilter);
          }
        });

        if (this.gradeSearchList.length > 0) {
          this.gradeSearchList.sort();
        }

        this.selectAllCheck();
        this.totalItems = participantsData.data.totalScholarsCount;
        this.isSearching = false;
      }
      else if (participantsData.status === this.noDataApiResponseCode) {
        this.items = [];
        this.totalItems = 0;
        this.responseStatus = participantsData.status;
        if (this.globalSearch.length && this.globalSearch.length >= 3) {
          this.globalSearchEmptyState = true;
        }
      }
      else {
        this.items = [];
        this.totalItems = 0;
        this.isSearching = payload.search
          ? true
          : false;
      }
    }
    if (this.initialTotalItems === APP_CONST.ZERO) {
      this.initialTotalItems = this.totalItems;
    }
  }

  selectAllCheck(): void {
    this.items && this.items.forEach((participant) => {
      table.allDataSelected && table.allDataSelected.forEach((elementChecked: (ScholarDto & { guardians: string; programsAndSessions: string; selected: boolean })) => {
        if (elementChecked.guid === participant.guid && participant.firstName === elementChecked.firstName) {
          participant.selected = elementChecked.selected;
        }
      });
    });
    if (table.allSelect) {
      table.mutateSelectAll(true);

      this.items && this.items.forEach((participant) => {
        participant.selected = true;
      });
      this.items && this.items.forEach((participant) => {
        table.unSelectData && table.unSelectData.length && table.unSelectData.forEach((elementChecked: (ScholarDto & { guardians: string; programsAndSessions: string; selected: boolean })) => {
          if (elementChecked.guid === participant.guid && participant.firstName === elementChecked.firstName) {
            participant.selected = false;
          }
        });
      });
    }
  }

  clearSelection(): void {
    table.mutateSelectAll(false);
    table.mutateFewCheckData('');
    table.mutateAllSelectedData([]);
    table.mutateUnSelectedData([]);
    const element = document.getElementById('all-data-checkbox') as HTMLInputElement;
    if (element) {
      element.checked = false;
    }
    this.items && this.items.forEach((element) => {
      element.selected = false;
    });
  }

  setPage(page: number): void {
    accountParticipantsStore.mutateParticipantsPayload({ ...this.participantsPayload, sortField: this.sortField, sortDir: this.sortDir, page });
    this.page = page;
    this.manageList();
  }

  callHover(value: string, event: any, id?: number): void {
    this.hoverText = value;
    if (id) {
      this.hoverId = id;
    }
    const boundBox = event && event.target && event.target.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    this.hoverStyleObj = {
      position: 'fixed',
      top: `${coordY + 45}px`,
      left: `${(coordX + 15)}px`
    };
  }

  callHoverOut(): void {
    this.hoverText = '';
    this.hoverId = 0;
  }

  clearSearch(): void {
    this.isClearSearch = APP_CONST.TRUE;
    this.searchLoader = false;
    this.pageStatus = this.isLoaderVisible
      ? false
      : this.pageStatus;
    this.globalSearch = '';
    this.isSearching = false;
    this.resetPage();
    this.manageList();
  }

  resetPage(): void {
    this.page = APP_CONST.ONE;
    accountParticipantsStore.mutateParticipantsPayload({ ...this.participantsPayload, page: APP_CONST.ONE });
    this.$store.dispatch('globalModule/resetPageCount', true);
  }

  removeChecked(): void {
    const element = document.getElementById('all-data-checkbox') as HTMLInputElement;
    if (element) {
      element.checked = false;
    }
    table.mutateFewCheckData('');
    table.mutateAllSelectedData([]);
  }

  filterColumns(displayColumn: ColumnConfig[]): void {
    let isSelected = false;
    displayColumn && displayColumn.length && displayColumn.forEach((column) => {
      if (column.display) {
        isSelected = true;
      }
    });
    this.displayAll = isSelected;
    this.clearAllColumns(this.displayAll);
    accountParticipantsStore.mutateSettingsData(displayColumn);
    accountParticipantsStore.mutateParticipantsPayload({ ...this.participantsPayload, sortField: this.sortField, sortDir: this.sortDir });
  }

  clearAllColumns(displayAll: boolean): void {
    const isSearchFieldFixed = this.tableHeaderConfig.FIXED.map((e) => {
      return e.value;
    }).indexOf(this.searchField);

    const isSortFieldFixed = this.tableHeaderConfig.FIXED.map((e) => {
      return e.value;
    }).indexOf(this.sortField);
    if (!displayAll && isSearchFieldFixed === APP_CONST.MINUS_ONE) {
      this.search = this.searchField === APP_CONST.FIRST_NAME || this.searchField === APP_CONST.LAST_NAME
        ? this.search
        : '';
      this.searchField = this.searchField === APP_CONST.FIRST_NAME || this.searchField === APP_CONST.LAST_NAME
        ? this.searchField
        : '';
      accountParticipantsStore.mutateParticipantsSearchObj({ ...this.searchParticipant });
      this.manageList();
    }
    if (!displayAll && isSortFieldFixed === APP_CONST.MINUS_ONE) {
      this.sortDir = this.sortField === APP_CONST.FIRST_NAME || this.sortField === APP_CONST.LAST_NAME
        ? this.sortDir
        : APP_CONST.ZERO;
      this.sortField = this.sortField === APP_CONST.FIRST_NAME || this.sortField === APP_CONST.LAST_NAME
        ? this.sortField
        : '';
      accountParticipantsStore.mutateSortPayload({ ...this.participantsPayload, sortField: this.sortField, sortDir: this.sortDir });
      accountParticipantsStore.mutateParticipantsSearchObj({ ...this.searchParticipant });
      this.manageList();
    }
  }

  filterDisplayColumn(column: ColumnConfig): void {
    if (!column.display) {
      if (this.participantsPayload && (column.value === this.participantsPayload.searchField)) {
        this.search = '';
        this.searchField = '';
        const columnName = column.value;
        accountParticipantsStore.mutateParticipantsSearchObj({ ...this.searchParticipant, [columnName]: '' });
        this.manageList();
      }
      if (column.value === this.participantsPayload.sortField) {
        this.sortDir = 0;
        this.sortField = '';
        accountParticipantsStore.mutateSortPayload({ ...this.participantsPayload, sortField: this.sortField, sortDir: this.sortDir });
        this.manageList();
      }
    }
  }

  formatDate(date: string): string {
    return APP_UTILITIES.formatShortDate(date);
  }

  sortParticipantsData(sortObj: { sortField: string; sortDirection: number; customField: string }): void {
    this.pageStatus = this.isLoaderVisible
      ? false
      : this.pageStatus;
    this.sortField = sortObj.sortField;
    this.sortDir = sortObj.sortDirection;
    this.page = 1;
    accountParticipantsStore.mutateSortPayload({ ...this.participantsPayload, sortField: this.sortField, sortDir: this.sortDir });
    accountParticipantsStore.mutateParticipantsPayload({ ...this.participantsPayload, sortField: this.sortField, sortDir: this.sortDir });
    this.globalSearch = this.globalSearch
      ? this.globalSearch
      : '';
    this.manageList();
  }

  searchParticipantsData(search: { searchField: string; isExactMatch: boolean }): void {
    this.pageStatus = this.isLoaderVisible
      ? false
      : this.pageStatus;
    this.page = 1;
    this.searchField = search.searchField;
    this.search = this.searchParticipant[search.searchField]
      ? this.searchParticipant[search.searchField]
      : '';
    this.globalSearch = this.globalSearch
      ? this.globalSearch
      : '';
    if (search.searchField) {
      accountParticipantsStore.mutateParticipantsSearchObj({
        ...this.searchParticipant, [search.searchField]: (this.searchParticipant && this.searchParticipant[search.searchField]
          ? this.searchParticipant[search.searchField]
          : ''), isExactMatch: search.isExactMatch
      });
      accountParticipantsStore.mutateParticipantsPayload({
        ...this.participantsPayload, searchField: this.searchField, sortDir: this.sortDir, isExactMatch: search.searchField
          ? search.isExactMatch
          : false
      });
    }
    else {
      accountParticipantsStore.mutateParticipantsSearchObj({ ...this.searchParticipant });
      accountParticipantsStore.mutateParticipantsPayload({
        ...this.participantsPayload, searchField: this.searchField, sortDir: this.sortDir
      });
    }
    if (this.searchField.length) {
      this.localSearch = true;
    }
    this.clearSelection();
    this.manageList();
    APP_UTILITIES.setCookie('participants_globalSearch', this.globalSearch, 1);
  }

  presistResize(resize: { column: any; maxWidth: any; minWidth: any }): void {
    accountParticipantsStore.mutateResizeData(resize);
  }

  callApionBackSpace(): void {
    if (this.globalSearch === '') {
      this.globalSearchApiStatus = 204;
      this.searchLoader = false;
      this.manageList();
      accountParticipantsStore.mutateParticipantsPayload(this.participantsPayload);
    }
  }

  searchGlobalData(): void {
    if (this.globalSearch.length >= 3) {
      this.analyticsService.track(analyticsEventNames.PARTICIPANT_TABLE_SEARCHED);
      this.resetPage();
      this.searchLoader = true;
      this.manageList();
    }
  }

  destroyed(): void {
    table.mutateSelectAll(false);
    table.mutateFewCheckData('');
    table.mutateAllSelectedData([]);
    table.mutateUnSelectedData([]);
    const element = document.getElementById('all-data-checkbox') as HTMLInputElement;
    if (element) {
      element.checked = false;
    }
  }

  openParticipantDetails(participant: ScholarDto): void {
    this.selectedParticipant = participant;
    this.showFlyoutPanel = true;
    this.analyticsService.track(analyticsEventNames.PARTICIPANT_SUMMARY_FLYOUT_VIEWED);

    // TODO: Once Profiles is on prod and stable delete this whole block
    if (!this.profilesAdminViewEnabled) {
      const { firstName, lastName, guid, programSessionDetails } = participant;
      const programId = programSessionDetails.length ? programSessionDetails[0].programId : 0;

      const payloadForEnrollment = {
        scholarData: [{
          firstName,
          lastName,
          scholarId: guid,
          programId,
          siteId: 0,
        }],
        isType: 'scholar',
        scholarCurrentIndex: 0,
      };
      APP_UTILITIES.setCookie('manage_families_scholar_details', JSON.stringify(payloadForEnrollment), 1);
    }
  }

  onParticipantsDetailsClose(): void {
    this.selectedParticipant = null;
    // TODO: Once Profiles is on prod and stable delete this line
    APP_UTILITIES.deleteCookie('manage_families_scholar_details');
  }

  deleteSelectedParticipants(): void {
    // TODO: Add delete action
    if (UIkit.modal("#delete-popup")) {
      UIkit.modal("#delete-popup").show();
    }
  }

  getProgramAndSession(programSessionDetails: ProgramSessionDetailsDto[]): string {
    if (programSessionDetails.length === 0) return '';

    const firstProgram = programSessionDetails[0];
    const firstSession = firstProgram.sites && firstProgram.sites.length ? firstProgram.sites[0] : null;
    const programAndSessionName = firstSession ?
      `${firstProgram.programName} - ${firstSession.siteName}`
      : `${firstProgram.programName}`;

    const totalEnrolledSites = programSessionDetails.reduce((count, program) => count + program.sites.length, 0);
    const remaining = totalEnrolledSites > 1 ? ` +${totalEnrolledSites - 1}` : '';

    return `${programAndSessionName}${remaining}`;
  }

  getColumnConfigFromLocalStorage(participantsColumnsInLocalStorage: ColumnConfig[]) {
    const columnsInLocalStorage = new Map(participantsColumnsInLocalStorage.map(column => [column.value, column]));
    const allColumns = accountParticipantsStore.participantColumnConfig;

    const columnsConfig = allColumns.flatMap(column => {
      const columnFromLocalStorage = columnsInLocalStorage.get(column.value);
      return columnFromLocalStorage ? [columnFromLocalStorage] : [column];
    });

    return columnsConfig;
  }

  /**
   * Handles the deletion of participants.
   */
  async handleDeleteParticipant(): Promise<void> {
    const payload: DeleteParticipantPayload = {
      accountId: this.getAccountId(),
      participantsGuid: this.selectedItems.map(({ guid }) => guid)
    }

    try {
      const res = await deleteParticipants(payload)

      if (res.status === APP_CONST.RESPONSE_200) {
        APP_UTILITIES.showToastMessage(
          this.getScreenText('SUCCESSFULLY_DELETED'),
          ToastType.Success
        );

        this.manageList();
        this.clearSelection();
      } else {
        APP_UTILITIES.showToastMessage(
          this.getScreenText('FAILED_TO_DELETE'),
          ToastType.Error
        );
      }
    } catch (err) {
      APP_UTILITIES.showToastMessage(
        this.getScreenText('FAILED_TO_DELETE'),
        ToastType.Error
      );
    }
  }

  /**
   * Retrieves the account ID.
   * @returns The account ID of the current user.
   */
  private getAccountId(): number {
    let { accountId } = APP_UTILITIES.coreids();
    if (!accountId) {
      // BX users don't have the account id in the user roles property
      return Number(APP_UTILITIES.getCookie('accountId'));
    }
    return accountId;
  }

  /**
   * Handles the change of the number of items per page.
   *
   * @param {number} numberOfItems - The number of items per page.
   */
  itemsPerPageChanged(numberOfItems: number) {
    this.searchCount = numberOfItems;
    this.page = 1;
    accountParticipantsStore.mutateParticipantsPayload({ ...this.participantsPayload, count: numberOfItems });
    LocalStorage.saveInLocalStorage(`${this.tableName}_searchCount`, numberOfItems);
    this.manageList();
  }

  async downloadCsv(): Promise<void> {
    const payload = APP_CONST.SCHOLAR_FAMILY_PAYLOAD;
    payload.search = this.search;
    payload.accountId = Number(this.accountId);
    payload.page = this.page;
    payload.count = this.searchCount;
    payload.sortField = this.sortField
      ? this.sortField
      : 'firstName';
    payload.sortDir = this.sortDir
      ? this.sortDir
      : 1;
    payload.globalSearch = this.globalSearch;
    payload.searchField = this.searchField;
    payload.isExactMatch = this.participantsPayload && this.participantsPayload.isExactMatch
      ? this.participantsPayload.isExactMatch
      : false;
    const { data, fileName } = await getParticipantsCsv(payload);
    APP_UTILITIES.downloadCsv(data, fileName)
  }
}
